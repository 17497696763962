<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="chart-card__root">
          <v-card-title class="pb-0">
            <v-row class="justify-space-between">
              <v-col cols="auto">
                <span>{{ $t(ordersData.label) }}</span>
              </v-col>
              <v-spacer/>
              <v-col cols="auto" class="align-self-end chart-card__info">
                <template v-if="ordersData.series[this.ordersData.series.findIndex(item => item.name === 'amount')].data.length">
                  <p>{{ $t('Orders count', {symbol: ':'}) }} {{ totalOrderAmount }}</p>
                </template>
                <template v-if="ordersData.series[this.ordersData.series.findIndex(item => item.name === 'revenue')].data.length">
                  <p>{{ $t('Orders revenue', {symbol: ':'}) }} {{ totalOrderRevenue }}</p>
                </template>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="chart-card__content">
            <vue-apex-charts v-if="!this.ordersData.options.viewOptions.loading && !ordersData.error.label" height="380" :options="ordersData.options" :series="ordersData.series"></vue-apex-charts>
            <v-progress-circular
              v-if="this.ordersData.options.viewOptions.loading && !ordersData.error.label"
              :size="50"
              color="primary"
              indeterminate
              class="chart-card__loader"
            />
            <div v-else class="chart-card__error">
              <data-not-found :error="ordersData.error" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {mapActions, mapGetters} from "vuex";
import DataNotFound from "@/components/DataNotFound.vue";

export default {
  name: "DashboardCharts",
  components: {
    DataNotFound,
    VueApexCharts,
  },
  props: {
    timeRange: {
      type: Object,
      required: true
    }
  },
  data: (vm) => ({
    ordersData: {
      label: 'Sales Performance',
      options: {
        chart: {
          type: 'line',
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          }
        },
        xaxis: {
          categories: [],
          tooltip: {
            enabled: false
          }
        },
        fill: {
          opacity: 1
        },
        viewOptions: {
          loading: true
        },
        markers: {
          size: 6,
        },
        legend: {
          formatter: (val) => vm.$t(val),
          tooltipHoverFormatter: undefined
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: (val) => vm.$t(val)
              }
            },
            {
              title: {
                formatter: (val) => vm.$t(val)
              },
              formatter: undefined
            },
          ]
        },
      },
      series: [
        {
          name: 'amount',
          data: []
        },
        {
          name: 'revenue',
          data: []
        }
      ],
      error: {
        icon: '',
        label: '',
        description: '',
      }
    },
    currentCurrency: ''
  }),
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
    }),
    totalOrderAmount () {
      return this.ordersData.series[this.ordersData.series.findIndex(item => item.name === 'amount')].data.reduce((a, b) => a + b, 0);
    },
    totalOrderRevenue () {
      return `${parseFloat(this.ordersData.series[this.ordersData.series.findIndex(item => item.name === 'revenue')].data.reduce((a, b) => a + parseFloat(b), 0)).toFixed(2)} ${this.currentCurrency}`;
    }
  },
  methods: {
    ...mapActions({
      loadOrdersData: 'dashboard/loadOrderAmounts',
    }),
    getOrderData () {
      if (this.timeRange.date.to && this.timeRange.date.from) {
        this.ordersData.options.viewOptions.loading = true;
        this.ordersData.error.icon = '';
        this.ordersData.error.label = '';
        this.ordersData.error.description = '';
        this.loadOrdersData({ token: this.token, date_from: this.timeRange.date.from, date_to: this.timeRange.date.to})
          .then(response => {
            if (!response.data.length) {
              this.ordersData.options.xaxis.categories = [];
              this.ordersData.series[this.ordersData.series.findIndex(item => item.name === 'amount')].data = [];
              this.ordersData.error.icon = 'mdi-database-sync';
              this.ordersData.error.label = this.$t('No results found.');
              this.ordersData.error.description = this.$t('Try another selection.');
            } else {
              this.ordersData.options.xaxis.categories = response.data.map(item => item.period);
              this.ordersData.series[this.ordersData.series.findIndex(item => item.name === 'amount')].data = response.data.map(item => item.amount);
              this.ordersData.series[this.ordersData.series.findIndex(item => item.name === 'revenue')].data = response.data.map(item => {
                this.ordersData.options.tooltip.y[1].formatter = (val, opts) => `${parseFloat(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]).toFixed(2)} ${item.currency}`
                return parseFloat(item.revenue).toFixed(2)
              });
              this.ordersData.options.legend.tooltipHoverFormatter = function(val, opts) {
                if (val.toLowerCase() === 'revenue') {
                  return val + ' - <strong>' + `${parseFloat(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]).toFixed(2)} ${response.data[0].currency}` + '</strong>'
                }
                return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
              }
              this.currentCurrency = response.data[0].currency;
            }
          })
          .catch(error => {
            console.error(error);
            this.ordersData.options.xaxis.categories = [];
            this.ordersData.series[this.ordersData.series.findIndex(item => item.name === 'amount')].data = [];
            this.ordersData.error.icon = 'mdi-database-remove';
            this.ordersData.error.label = this.$t('No results found.');
            this.ordersData.error.description = this.$t('Try again later or choose another selection.');
          })
          .finally(() => {
            this.ordersData.options.viewOptions.loading = false;
          })
      }
    },
  },
  watch: {
    timeRange () {
      this.getOrderData();
    }
  }
}
</script>

<style scoped lang="scss">
  .chart-card {
    &__root {
      height: 100%;
      min-height: 513px;
      display: flex;
      flex-direction: column;
    }
    &__info {
      p {
        margin: 0;
      }
    }
    &__content {
      flex: 1 1 auto;
      position: relative;
      max-width: calc(100% - 14px);
    }
    &__loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__error {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
</style>
